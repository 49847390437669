<template>
  <b-modal
    id="modal-add-stock-eceran"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row
        class="align-items-center justify-content-between"
      >
        <b-col lg="3">
          <div
            style="cursor: pointer;"
            @click="closeModal"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ChevronLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col lg="6" class="text-center">
          <h1 class="text-dark h6 font-weight-bolder mb-0">
            {{ isEdit ? 'Detail Stok Eceran' : 'Tambahkan Stok Eceran'}}
          </h1>
        </b-col>
        <b-col lg="3" class="text-right">
          <b-button
            v-if="isEdit == false"
            class="px-3"
            :disabled="isLoading"
            @click.prevent="submitRetail"
          >
            <b-spinner small v-if="isLoading" />
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </header>

    <b-row class="mx-0 mt-5 pt-5 justify-content-center">
      <b-col lg="5">
        <b-row>
          <b-col cols="12">
            <input
              v-model="formPayload.date"
              type="date"
              hidden
            >
            <b-form-group
              class="custom__form--input"
            >
              <label for="harga-barang">
                Pilih Lokasi SKU
              </label>
              <b-form-select
                v-model="formPayload.warehouse_uuid"
                placeholder="Pilih Lokasi SKU"
                text-field="title"
                :options="optionsWarehouse"
                :disabled="isEdit"
              />
              <small
                v-if="message.warehouse_uuid && Array.isArray(message.warehouse_uuid) && message.warehouse_uuid.length > 0"
                class="text-error fw-bold-500 size12"
              >{{ message.warehouse_uuid.join(', ') }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Pilih produk eceran"
              label-for="v-eceran"
            >
              <v-select
                v-model="formPayload.retail_product_uuid"
                label="title"
                placeholder="Pilih Produk Eceran"
                :options="options"
                :reduce="option => option.label"
                @search="onSearch"
                :disabled="!formPayload.warehouse_uuid || isEdit"
              />
              <small
                v-if="message.retail_product_uuid && Array.isArray(message.retail_product_uuid) && message.retail_product_uuid.length > 0"
                class="text-error fw-bold-500 size12"
              >{{ message.retail_product_uuid.join(', ') }}</small>
              <small
                v-if="filteredWarehouses.length > 0"
                class="size12 text-dark text-darken-5 fw-bold-500"
              >{{ result.alias_name || '-' }}, Stok eceran tersedia: {{ result.total_stock }}</small>
              <br>
              <small 
                v-if="filteredWarehouses.length > 0"
                class="size12 text-dark text-darken-5 fw-bold-500"
              >
                {{ result.alias_name }} 
                <small v-for="(warehouse, index) in filteredWarehouses" :key="index">
                  , Stok induk tersedia: {{ warehouse.stock }}
                </small>
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="my-2"
          >
            <div class="d-flex">
              <hr
                class="border border-light-line my-1"
                style="width: 30%"
              >
              <div class="d-flex mx-2">
                <div class="">
                  <feather-icon icon="ArrowDownIcon" />
                </div>
                <span>
                  Memecah dari
                </span>
              </div>
              <hr
                class="border border-light-line my-1"
                style="width: 30%"
              >
            </div>
          </b-col>
          <b-col cols="12">
            <div class="custom__form--input">
              <label for="harga-barang">
                Produk induk sejumlah
              </label>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="discount1"
                  v-model="formPayload.qty_parent"
                  placeholder="0"
                  class="custom__input border-0"
                  style="border-top-right-radius: 0;border-bottom-right-radius: 0;"
                  :disabled="isEdit"
                />
                <b-input-group-append>
                  <b-input-group-text style="background: #F1F2F5; border: none">
                    <span class="text-dark text-darken-4">{{ result.parent_product && result.parent_product.uom.name || '' }}</span>
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="message.qty_parent && Array.isArray(message.qty_parent) && message.qty_parent.length > 0"
                class="text-error fw-bold-500 size12"
              >{{ message.qty_parent.join(', ') }}</small>
            </div>
            <hr class="border border-light-line mt-2">
          </b-col>
          <b-col
            cols="12"
            class="mt-2"
          >
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="mb-0 text-dark fw-bold-600 size14">
                Menghasilkan Produk Eceran Sejumlah
              </h4>
              <h3 class="mb-0 text-primary fw-bold-600 size18">
                {{ formPayload.qty_parent * result.retail_convertion || 0 }} {{ result.uom && result.uom.name || '' }}
              </h3>
            </div>
            <div
              class="d-flex justify-content-between align-items-center p-2 mt-3"
              style="background: linear-gradient(267.31deg, #68DBD0 -22.8%, #45B6AB 82.93%);border-radius: 12px;"
            >
              <h6 class="text-white size14 fw-bold-700 mb-0">
                Produk eceran belum ada?
              </h6>
              <b-button
                class="bg-white border-12"
                @click="$router.push({ name: 'master-product.retail' })"
              >
                + Tambahkan
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BButton, BModal, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupText, BFormSelect, BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BFormSelect,
    BSpinner,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    submitRetail: {
      type: Function,
    },
    messages: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    detailResult: {
      type: Object,
    },
  },
  data() {
    return {
      selected: 'Paku 3/4", Stok tersedia: 15 Dus',
      options: [],
      optionsWarehouse: [],
      result: {},
      formPayload: {
        retail_product_uuid: '',
        date: moment(new Date()).format('YYYY-MM-DD'),
        warehouse_uuid: '',
        qty_parent: 0,
      },
      message: '',
      isLoading: false,
      isEdit: false,
    }
  },
  watch: {
    detailResult: {
      handler(value) {
        this.formPayload = {
          retail_product_uuid: value.product?.uuid,
          date: moment(new Date()).format('YYYY-MM-DD'),
          warehouse_uuid: value.warehouse?.uuid,
          qty_parent: value.qty_parent,
        }
      },
      deep: true,
    },
    '$store.state.stockWarehouse.isLoading': function (value) {
      this.isLoading = value
    },
    '$store.state.stockWarehouse.isEdit': function (value) {
      this.isEdit = value
    },
    'formPayload.retail_product_uuid': function (value) {
      if (value !== '') {
        this.getDataProductDetail(value)
      }
    },
    'formPayload.warehouse_uuid': function (value) {
      if (this.isEdit) {
        this.getDataProduct()
      } else {
        this.result = ''
        this.formPayload.retail_product_uuid = ''
        this.getDataProduct()
      }
    },
    '$store.state.stockWarehouse.payloadRetail': {
      handler(value) {
        this.formPayload = value
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        this.$store.commit('stockWarehouse/setPayloadRetail', value)
      },
      deep: true,
    },
    messages(value) {
      this.message = value
    },
  },
  created() {
    // this.getDataProduct()
    this.getkWarehouse()
  },
  mounted() {
    if (this.isEdit === true) {
      this.formPayload = {
        retail_product_uuid: this.detailResult.product?.uuid,
        date: moment(new Date()).format('YYYY-MM-DD'),
        warehouse_uuid: this.detailResult.warehouse?.uuid,
        qty_parent: this.detailResult.qty_parent,
      }
    }
  },
  computed: {
    filteredWarehouses() {
      if (this.result && this.result.parent_product && this.result.parent_product.stock_in_warehouse) {
        const selectedWarehouseUuid = this.formPayload.warehouse_uuid;
        return this.result.parent_product.stock_in_warehouse.filter(warehouse => {
          return warehouse.uuid === selectedWarehouseUuid;
        });
      }
      return [];
    }
  },
  methods: {
    getkWarehouse() {
      this.$store.dispatch('warehouse/getData', {
        params: {
          per_page: 50,
        },
      }).then(result => {
        // this.result = result.data.data
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < result.data.data.data.length; index++) {
          const element = result.data.data.data[index]
          this.optionsWarehouse.push({
            value: element.uuid,
            title: element.name,
          })
        }
        this.optionsWarehouse.unshift({ value: '', title: 'Pilih Lokasi SKU' })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.searchProduct(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.options = []
          this.getDataProduct()
          loading(false)
        }, 500)
      }
    },
    searchProduct: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('uom/getData', {
        uuid: '',
        params: {
          search: escape(search),
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionUom = []
          response.data.data.data.forEach(item => {
            vm.options.push({
              label: item.uuid,
              title: item.alias_name,
            })
          })
          loading(false)
        })
    }, 300),
    getDataProduct() {
      this.$store.dispatch('parentProduct/getData', {
        uuid: '',
        params: {
          warehouse_uuid: this.formPayload.warehouse_uuid,
          per_page: 50,
          product_type: 'retail',
        },
      }).then(result => {
        this.options = []
        result.data.data.data.forEach(element => {
          this.options.push({
            label: element.uuid,
            title: element.alias_name,
          })
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getDataProductDetail(id) {
      this.$store.dispatch('parentProduct/getData', {
        uuid: id || '',
        params: {
          product_type: 'retail',
        },
      }).then(result => {
        this.result = result.data.data
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    closeModal() {
      // this.$store.commit('stockWarehouse/setPayloadRetail', {})
      this.$bvModal.hide('modal-add-stock-eceran')
      this.message = ''
      this.formPayload = {
        retail_product_uuid: '',
        date: moment(new Date()).format('YYYY-MM-DD'),
        warehouse_uuid: '',
        qty_parent: '',
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#modal-add-stock-eceran {
  .modal-dialog {
    max-width: 100%;
    margin: 0;

    .modal-content {
      height: 100vh;

      .modal-body {
        padding: 0;
        overflow-y: scroll;

        .progress-bar-success {
          background-color: #EFF1F5;
          .progress-bar {
            background: linear-gradient(269.89deg, #6CDFD4 0%, #45B6AB 98.81%) !important;
            box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
            border-radius: 20px;
          }
        }

        label {
          font-size: 14px;
          color: $dark;
          font-weight: 600;
        }

        .vs__open-indicator {
          fill: rgba(60, 60, 60, 0.5);
        }

        .custom__input {
          height: 52px;
          background: #EFF1F5;
          border: 1px solid #E4E5EC;
          border-radius: 8px;
        }

        .vs__dropdown-toggle {
          height: 52px;
          background-color: #EFF1F5;
          border: 1px solid #E4E5EC;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
